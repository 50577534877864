import { sanitize } from "isomorphic-dompurify";
import Arrow from "../../../images/arrow_dark.svg";
import { InlineLinkType } from "../../../utils/baseTypes";
import { unlinkWiki } from "../../../utils/unlinkWiki";
import { useLenis } from "lenis/dist/lenis-react";

interface ScrollLinkProp {
  link: InlineLinkType;
  className?: string;
  behavior?: ScrollBehavior;
  extend?: boolean;
}

interface IScrollTo {
  id: string;
  behavior?: ScrollBehavior;
  extend?: boolean;
}
const scrollTo = ({ id, behavior = "smooth", extend = false }: IScrollTo) => {
  const element = document.getElementById(id);
  // 56 is the height of bottom nav and 70 is overall height of teh navbar
  const offset_extend = window.innerWidth > 1023 ? 56 : 70;
  extend = window.innerWidth < 1024 ? true : extend;

  if (element) {
    window.scroll({
      top: extend ? element.offsetTop - offset_extend : element.offsetTop,
      behavior: behavior,
    });
  }
};

export default function ScrollLink({ link, behavior, extend }: ScrollLinkProp) {
  const lenis = useLenis();
  return (
    <div className="scroll-link-wrapper">
      <span
        className="a"
        onClick={() => {
          const element = document.getElementById(link.id!);
          // 56 is the height of bottom nav and 70 is overall height of teh navbar
          const offset_extend = window.innerWidth > 1023 ? 56 : 70;
          extend = window.innerWidth < 1024 ? true : extend;

          if (element) {
            lenis?.scrollTo(
              extend ? element.offsetTop - offset_extend : element.offsetTop,
              {
                lerp: 0.05,
              }
            );
          }
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: sanitize(link.name) }}></span>
        <Arrow width={22.7} height={15.2} />
      </span>
    </div>
  );
}

export function ScrollLinkNoArrw({ link, className }: ScrollLinkProp) {
  return (
    <span
      onClick={() => {
        scrollTo({ id: link.id! });
      }}
      className={`a ${className ?? ""}`}
      dangerouslySetInnerHTML={{
        __html: sanitize(unlinkWiki(link.name)),
      }}
    ></span>
  );
}
